<template>
  <div class="epitel-quiz__questions" ref="quiz">
    <div class="epitel-quiz__questions-container">
      <picture
        v-show="
          questions[currentQuestionIndex] &&
          !questions[currentQuestionIndex].background
        "
      >
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/1_mob.jpg`"
          media="(max-width: 767px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/1_tab.jpg`"
          media="(max-width: 1220px)"
        />
        <source
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/1.jpg`"
          media="(min-width: 1221px)"
        />
        <img
          :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/1.jpg`"
          class="epitel-quiz__questions-bg"
        />
      </picture>
      <picture v-if="questions[currentQuestionIndex].background">
        <source
          :srcset="questions[currentQuestionIndex].background.mob"
          media="(max-width: 767px)"
        />
        <source
          :srcset="questions[currentQuestionIndex].background.tab"
          media="(max-width: 1220px)"
        />
        <source
          :srcset="questions[currentQuestionIndex].background.desk"
          media="(min-width: 1221px)"
        />
        <img
          :srcset="questions[currentQuestionIndex].background.desk"
          class="
            epitel-quiz__questions-bg epitel-quiz__questions-bg-illustration
          "
        />
      </picture>
      <div class="epitel-quiz__questions-text">
        <div
          class="epitel-quiz__questions-text_wrap"
          v-for="(item, qInd) in questions.slice(0, questions.length - 1)"
          :key="qInd"
          v-show="currentQuestionIndex === qInd"
          :style="
            item.background || item.image
              ? 'margin-top: 0; justify-content: flex-start'
              : ''
          "
        >
          <div class="epitel-quiz__questions-num">{{ item.num }}</div>
          <div class="epitel-quiz__questions-title" v-html="item.title"></div>
          <div
            class="epitel-quiz__questions-descr"
            v-if="!item.description && item.answers && item.answers.length"
          >
            Выберите один правильный ответ
          </div>
          <div
            class="epitel-quiz__questions-descr"
            v-html="item.description"
            v-if="item.description && (!item.answers || !item.answers.length)"
          ></div>
          <div
            class="epitel-quiz__questions-grid"
            v-if="item.answers && item.answers.length"
            :class="{
              'epitel-quiz__questions-grid_disable':
                answersList.length && answersList[qInd],
            }"
          >
            <div
              class="epitel-quiz__questions-grid_item"
              v-for="(answer, answerInd) in item.answers"
              v-html="answer.text"
              :key="answerInd"
              @click="setAnswer(qInd, answer, answerInd)"
              :class="{
                'epitel-quiz__questions-grid_item_correct':
                  answersList[qInd] &&
                  answersList[qInd].id === answerInd &&
                  answersList[qInd].is_correct,
                'epitel-quiz__questions-grid_item_incorrect':
                  answersList[qInd] &&
                  answersList[qInd].id === answerInd &&
                  !answersList[qInd].is_correct,
              }"
            ></div>
          </div>
          <div
            class="epitel-quiz__questions-tip"
            v-if="answersList.length && answersList[qInd]"
          >
            <div class="epitel-quiz__questions-tip_place">
              <div
                class="epitel-quiz__questions-tip_true"
                v-if="answersList[qInd] && answersList[qInd].is_correct"
              >
                Правильно
              </div>
              <div
                class="epitel-quiz__questions-tip_false"
                v-else-if="answersList[qInd] && !answersList[qInd].is_correct"
              >
                Неправильно
              </div>
            </div>
            <div
              class="epitel-quiz__questions-tip_correct"
              v-html="item.correct_text"
            ></div>
          </div>
          <div class="epitel-quiz__questions-nav">
            <div
              class="epitel-quiz__questions-back epitel-quiz__questions-arrow"
              @click="prevQuestion"
              v-if="currentQuestionIndex > 0"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 4.5L7.5 12L15 19.5"
                  stroke="#D8DADA"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
              Назад
            </div>
            <div
              class="epitel-quiz__questions-next epitel-quiz__questions-arrow"
              @click="nextQuestion"
              :class="{ 'epitel-quiz__questions-arrow_disabled': !enableNext }"
            >
              Вперед
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 4.5L16.5 12L9 19.5"
                  stroke="#D8DADA"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <picture v-if="item.image">
            <source :srcset="item.image.mob" media="(max-width: 767px)" />
            <source :srcset="item.image.tab" media="(max-width: 1220px)" />
            <source :srcset="item.image.desk" media="(min-width: 1221px)" />
            <img
              :srcset="item.image.desk"
              class="epitel-quiz__questions-image"
            />
          </picture>
        </div>
        <div
          class="epitel-quiz__questions-text_wrap"
          v-show="currentQuestionIndex === questions.length - 1"
          :style="'margin-top: 0; justify-content: flex-start'"
        >
          <div
            class="epitel-quiz__questions-title"
            v-html="questions[questions.length - 1].title"
          ></div>
          <div class="epitel-quiz__questions-nav epitel-quiz__questions-finish">
            <div
              class="epitel-quiz__questions-repeat button_empty-white button"
              @click="$emit('repeatTest')"
            >
              Начать сначала
            </div>
            <router-link
              :to="{ name: 'Epitelium', query: { ...$route.query } }"
              class="epitel-quiz__questions-repeat button_empty-white button"
            >
              На главную
            </router-link>
          </div>

          <div class="epitel-quiz__sources">
            <div class="epitel-quiz__sources-title">Источники</div>
            <ol style="color: #b2b4b4">
              <li>
                Corren J. J Allergy Clin Immunol Pract. 2019;7(5):1394-1403.
              </li>
              <li>Lambrecht BN, et al. Immunity. 2019;50(4):975-991.</li>
              <li>Lambrecht BN, et al. Nat Immunol. 2015;16(1):45-56.</li>
              <li>Ying S, et al. J Immunol. 2005;174(12):8183-8190.</li>
              <li>
                Liang Y, et al. J Allergy Clin Immunol. 2019;144(4):1025-1035.
              </li>
              <li>Li Y, et al. J Immunol. 2018:200(7):2253-2262.</li>
              <li>Tanaka J, et al. Clin Exp Allergy. 2009;39(1):89-100.</li>
              <li>
                Chesné J, et al. Am J Respir Crit Care Med.
                2014;190(10):1094-1101.
              </li>
              <li>Sy CB, et al. Front Physiol. 2016;7(214):1-7.</li>
              <li>
                Gauvreau GM, et al. Expert Opin Ther Targets.
                2020;24(8):777-792.
              </li>
              <li>Brusselle G, et al. Ann Am Thorac Soc. 2014;11:S322-S328.</li>
              <li>Pelaia G, et al. Nat Rev Drug Discov. 2012;11:958-997.</li>
              <li>Pasha MA, et al. Allergy Asthma Proc. 2019;40(3):138-145.</li>
              <li>Brusselle G, et al. Nat Med. 2013;19:977-979.</li>
              <li>Wu J, et al. Cell Biochem Funct. 2013;31:496-503.</li>
              <li>
                Divekar R, et al. Curr Opin Allergy Clin Immunol.
                2015;15(1):98-103.
              </li>
              <li>Hams E, et al. PNAS. 2014;111(1):367-372.</li>
              <li>An G, et al. Immunology. 2018;154(4):637- 650.</li>
              <li>An G, et al. Respirology. 2020:25(6):603-612.</li>
              <li>Kaur D, et al. Chest. 2012;142:76-85.</li>
              <li>Cao L, et al. Exp Lung Res. 2018;44:288-301.</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="epitel-quiz__questions-bottom">
        <div class="content">
          <div class="epitel-quiz__questions-bottom_container">
            <div class="epitel-quiz__questions-bottom_nav">
              <div
                class="epitel-quiz__questions-bottom_home"
                @click="$emit('playQuiz')"
              >
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9993 26.4992V20.4991C18.9993 20.2339 18.8939 19.9795 18.7064 19.792C18.5188 19.6044 18.2645 19.4991 17.9993 19.4991H13.9993C13.7341 19.4991 13.4797 19.6044 13.2922 19.792C13.1046 19.9795 12.9993 20.2339 12.9993 20.4991V26.4992C12.9993 26.7644 12.8939 27.0187 12.7064 27.2063C12.5189 27.3938 12.2646 27.4992 11.9994 27.4992L6.00012 27.5C5.86879 27.5 5.73874 27.4741 5.6174 27.4239C5.49606 27.3737 5.38581 27.3 5.29294 27.2071C5.20007 27.1143 5.12639 27.004 5.07613 26.8827C5.02587 26.7614 5 26.6313 5 26.5V14.9424C5 14.8031 5.02911 14.6653 5.08547 14.5379C5.14183 14.4105 5.22418 14.2963 5.32726 14.2026L15.3266 5.1107C15.5106 4.94334 15.7505 4.85059 15.9993 4.85059C16.2481 4.85058 16.4879 4.94331 16.672 5.11066L26.6727 14.2025C26.7758 14.2963 26.8582 14.4105 26.9145 14.5379C26.9709 14.6653 27 14.8031 27 14.9425V26.5C27 26.6313 26.9741 26.7614 26.9239 26.8827C26.8736 27.004 26.7999 27.1143 26.7071 27.2071C26.6142 27.3 26.5039 27.3737 26.3826 27.4239C26.2613 27.4742 26.1312 27.5 25.9999 27.5L19.9991 27.4992C19.7339 27.4992 19.4796 27.3938 19.2921 27.2063C19.1046 27.0187 18.9993 26.7644 18.9993 26.4992V26.4992Z"
                    stroke="#44484B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="epitel-quiz__questions-bottom_tip">
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M26.25 15.5C26.25 21.7132 21.2132 26.75 15 26.75C8.7868 26.75 3.75 21.7132 3.75 15.5C3.75 9.2868 8.7868 4.25 15 4.25C21.2132 4.25 26.25 9.2868 26.25 15.5ZM28.125 15.5C28.125 22.7487 22.2487 28.625 15 28.625C7.75126 28.625 1.875 22.7487 1.875 15.5C1.875 8.25126 7.75126 2.375 15 2.375C22.2487 2.375 28.125 8.25126 28.125 15.5ZM14.6348 23.4275C13.9812 23.4275 13.5605 22.9549 13.5605 22.399L13.5605 14.1435C13.5605 13.5875 13.9812 13.115 14.6348 13.115H15.266C15.9196 13.115 16.373 13.5875 16.373 14.1435L16.373 22.399C16.373 22.9549 15.9196 23.4275 15.266 23.4275H14.6348ZM14.9334 8.00044C15.7276 7.98162 16.3413 8.57124 16.373 9.36498C16.406 10.1923 15.7612 10.7777 14.9334 10.7582C14.1231 10.739 13.5283 10.1749 13.5605 9.36498C13.5913 8.58942 14.1575 8.01882 14.9334 8.00044Z"
                    fill="#3C4242"
                  />
                </svg>
                <div class="epitel-quiz__questions-bottom_tip-text">
                  <b>References: 1.</b> Corren J. J Allergy Clin Immunol Pract.
                  2019;7(5):1594-1405. <b>2.</b> Lambrecht BN, et al. Immunity.
                  2019;50(4):975-991. <b>3.</b> Lambrecht BN, et al. Nat
                  Immunol. 2015;16(1):45-56. <b>4.</b> Ying S. et al. J Immunol.
                  2005;174(12):8183-8190. <b>5.</b> Liang Y, et al. J Allergy
                  Clin Immunol. 2019;144(4):1025-1035. B. Li Y, et al. J Immunol
                  2018:200(7):2253-2262. <b>7.</b> Tanaka J. et al. Clin Exp
                  Allergy. 2009:39(1):89-100. <b>8.</b> Chesne J. et al. Am J
                  Respir Crit Care Med. 2014;190(10):1094-1101. <b>9.</b> Sy CB.
                  et al. Front Physiol 2016:7214) F-7. <b>10.</b> Gouvreau GM,
                  et al. Expert Opin Ther Targets. 2020:2448) 777-792.
                  <b>11.</b> Brusselle G, et al Ann Am Thorac Soc.
                  2014;11:8322-8328 <b>12.</b> Pelnin G, et al. Nat Rev Drug
                  Discov 2012:11:958-997 <b>13.</b> Pasha MA, et al. Allergy
                  Asthma Proc 2019:40(3):138-145. <b>14.</b> Brusselle G, et al.
                  Nat Med 2013;19:977-979. <b>15.</b> Wu J, et al. Cell Biochem
                  Funct. 2013;31:496-503, <b>16.</b> Divekar R, et al. Curr Opin
                  Allergy Clin Immunol. 2015;15(1):98-103. <b>17.</b> Hams E, et
                  al. PNAS. 2014;111(1):367–372. <b>18.</b> An G, et al.
                  Immunology. 2018;154(4):637–650. <b>19.</b> An G, et al.
                  Respirology 2020;25(6):603-612. <b>20.</b> Kaur D, et al.
                  Chest. 2012;142:76-85. <b>21.</b> Cao L. et al. Exp Lung Res.
                  2018;44:288-301.
                </div>
              </div>
            </div>
            <div class="epitel-quiz__questions-bottom_progress">
              <div class="epitel-quiz__questions-bottom_line">
                <div
                  class="epitel-quiz__questions-bottom_bar"
                  :style="barWidth"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpiteliumQuizComp",
  data: () => ({
    currentQuestionIndex: 0,
    answersList: [],
  }),
  computed: {
    barWidth() {
      return `width: ${
        ((this.currentQuestionIndex + 1) / this.questions.length) * 100
      }%;`;
    },
    enableNext() {
      if (
        this.questions[this.currentQuestionIndex] &&
        this.questions[this.currentQuestionIndex].answers &&
        !!this.questions[this.currentQuestionIndex].answers.length
      ) {
        return (
          !!this.answersList.length &&
          (this.answersList[this.currentQuestionIndex] || false)
        );
      }
      return true;
    },
    questions() {
      return [
        {
          num: "Вопрос №1",
          title:
            "Какая ткань является источником избыточной иммунной реакции при тяжелой бронхиальной астме?",
          correct_text:
            "В ответ на внешние раздражители эпителий выделяет цитокины, которые могут провоцировать воспаления при тяжелой бронхиальной астме <sup>1-3</sup>",
          answers: [
            {
              text: "Гладкомышечная ткань",
              is_correct: false,
            },
            {
              text: "Эпителий",
              is_correct: true,
            },
            {
              text: "Серомуцинозная железистая ткань",
              is_correct: false,
            },
            {
              text: "Гиалиновый хрящ",
              is_correct: false,
            },
          ],
        },
        {
          num: "",
          title:
            "<span style='color: #C4D600'>У пациентов с бронхиальной астмой ЭПИТЕЛИАЛЬНЫЕ ЦИТОКИНЫ (TSLP, IL-33, IL-25)</span>",
          background: {
            mob: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/il1_mob.jpg`,
            tab: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/il1_tab.jpg`,
            desk: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/il1.jpg`,
          },
          description:
            "Могут инициировать воспаление, отдавая приказы низлежащим эффекторным клеткам<sup>1,6,9</sup>",
        },
        {
          num: "Вопрос №2",
          title:
            "Когда эпителиальные цитокины (TSLP, IL-33, IL-25) высвобождаются впервые? ",
          correct_text:
            "Эпителий высвобождает TSLP в ответ на различные повреждения  такие, как вирусы, аллергены, поллютанты/сигаретный дым, бактерии, механические повреждения и другие внешние стимулы<sup>1,6,9</sup>.",
          answers: [
            {
              text: "После активации эозинофилов",
              is_correct: false,
            },
            {
              text: "Вслед за продукцией IL-4",
              is_correct: false,
            },
            {
              text: "В ответ на воздействие на эпителий",
              is_correct: true,
            },
            {
              text: "После активации тучных клеток",
              is_correct: false,
            },
          ],
        },
        {
          num: "",
          title:
            "Избыточная экспрессия TSLP, IL-33, IL-25 может запускать патологическое воспаление, что провоцирует симптомы и обострения бронхиальной астмы.",
          image: {
            mob: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/q2.jpg`,
            tab: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/q2.jpg`,
            desk: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/q2.jpg`,
          },
        },
        {
          num: "Вопрос №3",
          title: "Какова роль TSLP в развитии воспаления?",
          correct_text:
            "Высвобождение TSPL может также оказывать влияние на нижлежащие патологические пути и эффекторные клетки, такие как эозинофилы и тучные клетки, приводя к выработке IL-5, IL-13, IgE<sup>1-3</sup>.",
          answers: [
            {
              text: "Способствует выработке IL-5",
              is_correct: false,
            },
            {
              text: "Способствует выработке IL-13",
              is_correct: false,
            },
            {
              text: "Способствует выработке IgE",
              is_correct: false,
            },
            {
              text: "Все ответы верны",
              is_correct: true,
            },
          ],
        },
        {
          num: "Вопрос №4",
          title: "В какой тип воспаления вовлечены TSLP, IL-33 и IL-25?",
          correct_text:
            "TSLP, IL-33 и IL-25 относятся к ключевым эпителиальным цитокинам, ответсвтенным за аллергическое, эозинофильное и не Т2-воспаление, приводя к гиперреактивности дыхательных путей <sup>1-3, 10-21</sup>.",
          answers: [
            {
              text: "Аллергическое воспаление",
              is_correct: false,
            },
            {
              text: "Эозинофильное воспаление",
              is_correct: false,
            },
            {
              text: "Не Т2-воспаление",
              is_correct: false,
            },
            {
              text: "Гиперреактивность дыхательных путей",
              is_correct: false,
            },
            {
              text: "Все ответы верны",
              is_correct: true,
            },
          ],
        },
        {
          num: "",
          title:
            "<span style='color: #C4D600'>Встречайте главных участников воспаления при тяжелой бронхиальной астме</span>",
          description:
            "Ключевые эпителиальные цитокины, такие, как TSLP, IL-33, IL-25 запускают сложный воспалительный каскад, включающий множественные патогенетические пути <sup>1,6,9</sup>. ",
          image: {
            mob: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/q4.jpg`,
            tab: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/q4.jpg`,
            desk: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/epitelium/quiz/q4.jpg`,
          },
        },
        {
          num: "Вопрос №5",
          title:
            "Что следует в воспалительном каскаде за эпителиальными цитокинами?",
          correct_text:
            "Так как эпителиальные цитокины находтся на вершине воспалительного каскада, именно за ними следует высвобождение IgE, IL-5 и IL-4<sup>1-3</sup>.",
          answers: [
            {
              text: "IgE",
              is_correct: false,
            },
            {
              text: "IL-5",
              is_correct: false,
            },
            {
              text: "IL-4",
              is_correct: false,
            },
            {
              text: "Все ответы верны",
              is_correct: true,
            },
          ],
        },
        {
          num: "Вопрос №6",
          title:
            "На какие типы клеток на прямую или косвенно влияют эпителиальные цитокины?",
          correct_text:
            "Эпителиальные цитокины находятся на вершине воспалительного каскада и воздействуют на широкий спектр клеток, включая тучные клетки, эозинофилы, дендритные клетки, базофилы, IgE-продуцирующие В клетки и гладкомышечные клетки<sup>1-4</sup>.",
          answers: [
            {
              text: "Тучные клетки",
              is_correct: false,
            },
            {
              text: "Эозинофилы",
              is_correct: false,
            },
            {
              text: "Дендритные клетки",
              is_correct: false,
            },
            {
              text: "Гладкомышечные клетки",
              is_correct: false,
            },
            {
              text: "Все ответы верны",
              is_correct: true,
            },
          ],
        },
        {
          title:
            "<span style='color: #C4D600'>У пациентов с бронхиальной астмой эпителиальные цитокины находятся на вершине воспалительного каскада <sup>1,6,9</sup></span>",
        },
      ];
    },
  },
  methods: {
    setAnswer(qInd, answer, answerInd) {
      this.$set(this.answersList, qInd, { ...answer, id: answerInd });
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.$refs.quiz.scrollTop = 0;
      }
    },
    prevQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.$refs.quiz.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.epitel-quiz {
  &__sources {
    max-width: fit-content;
    margin: auto;
  }
  &__questions-repeat {
    margin: auto;
    background-color: #000;
    @media screen and (max-width: 1220px) {
      margin: 0px 0 16px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
        margin: 0px 0 16px;
    }
  }
  &__sources-title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    color: #fff;
    margin-bottom: 24px;
    text-align: left;

    @media screen and (max-width: 767px) {
      font-size: 21px;
    }
  }
  &__questions {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 200000;
    @media screen and (max-width: 1220px) {
      overflow-y: auto;
    }
    &-image {
      max-width: 100%;
      flex-grow: 1;
      object-fit: contain;
    }
    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 0 48px;
      @media screen and (max-width: 767px) {
        height: auto;
        min-height: 100%;
      }
    }
    &-bottom {
      margin-top: auto;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;

      &-illustration {
        max-width: 1440px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &-text {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      margin-bottom: 30px;

      & picture {
        flex-grow: 1;
        display: flex;
        height: 1px;
        margin-top: 15px;

        @media screen and (max-width: 767px) {
          height: 100%;
        }
      }

      &_wrap {
        max-width: 856px;
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media screen and(max-width: 1220px) {
          max-width: 592px;
          margin-top: 64px;
        }
        @media screen and(max-width: 767px) {
          margin-top: 40px;
          max-width: 339px;
        }
      }
    }
    &-num {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #fafce9;
    }
    &-title {
      margin-top: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
      color: #fafce9;
      @media screen and(max-width: 767px) {
        margin-top: 16px;
        font-size: 21px;
        line-height: 26px;
      }
    }
    &-descr {
      margin-top: 48px;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #fafce9;
      text-align: center;
      @media screen and(max-width: 767px) {
        margin-top: 40px;
        font-size: 16px;
      }
    }
    &-grid {
      margin-top: 32px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      &_disable {
        pointer-events: none;
      }
      &_item {
        width: calc(50% - 12px);
        cursor: pointer;
        padding: 16px 0;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #d8dada;
        border: 1px solid #d8dada;
        border-radius: 999px;
        @media screen and(max-width: 1220px) {
          width: 100%;
        }
        @media screen and(max-width: 767px) {
          font-size: 16px;
          width: 100%;
        }

        &_correct {
          background-color: #6bc399;
          color: #fff;
        }

        &_incorrect {
          background-color: #cb4050;
          color: #fff;
        }
      }
    }
    &-tip {
      margin-top: 32px;
      &_place {
        font-family: "Roboto", sans-serif;
        margin-bottom: 32px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
      &_true {
        color: #6bc399;
      }
      &_false {
        color: #cb4050;
      }
      &_correct {
        margin-bottom: 48px;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #fafce9;
        text-align: center;
      }
    }
    &-nav {
      margin-top: 48px;
      display: flex;
      gap: 16px;
      @media screen and(max-width: 767px) {
        margin-top: 32px;
      }
    }
    &-finish {
      @media screen and(max-width: 1220px) {
        margin: 30px 0 48px;
      }
      @media screen and(max-width: 767px) {
        width: 100%;
        display: block;
        margin: 30px 0 48px;
      }
    }
    &-arrow {
      cursor: pointer;
      width: 121px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #d8dada;
      border: 1px solid #d8dada;
      background-color: #000;
      border-radius: 999px;
      &_disabled {
        opacity: 0.9;
        pointer-events: none;
      }
      @media screen and(max-width: 767px) {
        width: 162px;
      }
    }
    &-back {
      svg {
        margin-right: 8px;
      }
    }
    &-next {
      svg {
        margin-left: 8px;
      }
    }
    &-bottom {
      &_container {
        display: flex;
        align-items: center;
      }
      &_nav {
        margin-right: 118px;
        display: flex;
        width: 78px;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        @media screen and(max-width: 1220px) {
          margin-right: 32px;
        }
      }
      &_home,
      &_tip {
        cursor: pointer;
        position: relative;
      }
      &_tip-text {
        position: absolute;
        bottom: calc(100% + 30px);
        width: 50vw;
        left: -42px;
        background-color: #000;
        border: 1px solid #d9d9d9;
        padding: 16px;
        border-radius: 5px;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #fff;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden;

        @media screen and (max-width: 767px) {
          width: calc(100vw - 42px);
        }

        &::before {
          position: absolute;
          top: calc(100% - 12px);
          left: 46px;
          width: 23px;
          height: 23px;
          border-radius: 0 0 0 5px;
          border-left: 1px solid white;
          border-bottom: 1px solid white;
          display: block;
          background-color: #000;
          content: "";
          transform: rotate(-45deg);
        }

        & > b {
          font-weight: 700;
        }
      }
      &_tip {
        &:hover {
          .epitel-quiz__questions-bottom_tip-text {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      &_progress {
        width: 100%;
      }
      &_line {
        position: relative;
        width: 100%;
        border-top: 1px solid #d8dada;
        z-index: 0;
      }
      &_bar {
        position: absolute;
        top: -2px;
        left: 0;
        width: 0%;
        border: 2px solid #8dc53e;
        z-index: 50;
        transition: 0.3s linear;
      }
    }
  }
}
</style>
<style lang="scss">