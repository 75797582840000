<template>
  <div class="epitel-quiz">
    <div class="epitel-quiz__main" v-if="!quizStart">
      <div class="epitel-quiz__head">
        <div class="content">
          <div class="epitel-quiz__head-container">
            <div class="epitel-quiz__head-title">Оцените Ваши знания</div>
            <div class="epitel-quiz__head-subtitle">
              Давайте посмотрим, как много Вы знаете о роли эпителиальных
              цитокинов при тяжелой бронхиальной астме
            </div>
          </div>
        </div>
      </div>
      <div class="epitel-quiz__banner">
        <div class="content">
          <div class="epitel-quiz__banner-text">
            <!-- <div class="epitel-quiz__banner-subtitle mb-6">Шшшшш..</div> -->
            <div class="epitel-quiz__banner-title mb-6">
              <span style="color: #c4d600"
                >Детали допроса подозреваемых в деле<br />
                «Тяжелая бронхиальная астма»</span
              >
            </div>
            <div class="epitel-quiz__banner-subtitle mb-6">
              <!-- Вы. Да, именно Вы. -->
              <!-- <br /><br /> -->
              Оцените ваши познания о бронхиальной астме
            </div>
            <div
              class="button px-4 py-3 epitel-quiz__additional-button"
              @click="playQuiz"
            >
              Начать
            </div>
          </div>
        </div>
        <picture>
          <source
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/banner_mob.jpg`"
            media="(max-width: 767px)"
          />
          <source
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/banner_tab.jpg`"
            media="(max-width: 1220px)"
          />
          <source
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/banner.jpg`"
            media="(min-width: 1221px)"
          />
          <img
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/banner.jpg`"
            class="epitel-quiz__banner-img"
          />
        </picture>
        <!-- <img
        :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/quiz/banner.jpg`"
        class="epitel-quiz__banner-img"
      /> -->
      </div>
      <div class="content">
        <div class="epitel-quiz__start">
          <div class="epitel-quiz__first-block first-block">
            <div class="first-block__text-container">
              <div class="first-block__caption">Главная</div>
              <div class="first-block__description">
                Узнайте, каким образом ключевые эпителиальные цитокины (TSLP,
                IL-33 и IL-25) участвуют в развитии воспаления при тяжелой
                астме<sup style="color: inherit">1-5</sup>.
              </div>
              <router-link :to="{name: 'Epitelium', query: $route.query}" class="first-block__button button">На главную</router-link>
            </div>
            <div class="first-block__image-container">
              <img
                class="first-block__image"
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/epitelium/1.jpg`"
              />
            </div>
          </div>

          <InfoDrop :arrowColor="'#fff'" :item="sources" />
        </div>
      </div>
    </div>

    <EpiteluimQuizComp @playQuiz="playQuiz" @repeatTest="repeatTest" v-if="quizStart" :key="testkey"/>
  </div>
</template>

<script>
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";
import EpiteluimQuizComp from "@/components/pageComponents/EpiteluimQuizComp.vue";
import { bus } from "@/main"
export default {
  name: "EpiteliumQuiz",
  components: {
    InfoDrop,
    EpiteluimQuizComp
  },
  data: () => ({
    quizStart: false,
    testkey: 0
  }),
  computed: {
   
    sources() {
      return {
        title: "<span style='color: #fff'>Источники</span>",
        content: `
        <ol style="color: #B2B4B4">
        <li>Corren J. J Allergy Clin Immunol Pract. 2019;7(5):1394-1403. 2. Lambrecht BN, Hammad H, Fahy JV. Immunity. 2019;50(4):975-991. 3. Lambrecht BN, Hammad H. Nat Immunol. 2015;16(1):45-56. 4. Li Y, Wang W, Lv Z, et al. J Immunol. 2018;200(7):2253-2262. 5. Sy CB, Siracusa MC. Front Physiol. 2016;7(214):1-7.</li>
        </ol>
        `,
      };
    },
  },
  methods: {
    playQuiz() {
      this.quizStart = !this.quizStart;
      bus.$emit("scrollLock", this.quizStart);
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    repeatTest() {
      this.testkey++
    }
  },
};
</script>

<style lang="scss" scoped>
.epitel-quiz {
  &__head {
    padding-top: 77px;
    // padding-bottom: 84px;
    // background: linear-gradient(
    //     360deg,
    //     rgba(0, 0, 0, 0.6) -3.11%,
    //     rgba(0, 0, 0, 0) 11.88%
    //   ),
    //   linear-gradient(0deg, #05090e, #05090e);
    @media screen and (max-width: 1220px) {
      padding-top: 40px;
      padding-bottom: 65px;
      background: inherit;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    &-container {
      max-width: 590px;
      margin: 0 auto;
    }
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 34px;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
      color: #ffffff;
    }
    &-subtitle {
      margin-top: 12px;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
    }
  }
  &__banner {
    position: relative;
    height: 1080px;
    padding-top: 70px;
    @media screen and (max-width: 1220px) {
      height: 823px;
      padding-top: 0px;
    }
    @media screen and (max-width: 767px) {
      height: 665px;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      @media screen and (min-width: 1441px) {
        object-fit: contain;
      }
    }

    &-text {
      position: relative;
      z-index: 2;
    }

    &-subtitle {
      color: #fff;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      @media screen and (max-width: 1220px) {
        margin-bottom: 16px;
      }

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    &-title {
      color: #fff;
      text-align: center;
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 141.176% */

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;

    &-overflow {
      @media screen and (max-width: 1220px) {
        overflow-x: auto;
        margin: 0 -76px;
        padding-left: 76px;
      }

      @media screen and (max-width: 767px) {
        margin: 0 -16px;
        padding-left: 16px;
      }

      & img {
        @media screen and (max-width: 1220px) {
          width: 120vw;
        }
        @media screen and (max-width: 767px) {
          width: 200vw;
        }
      }
    }
  }

  &__additional {
    margin-top: 48px;
    padding: 48px;
    border: 1px solid #50b5dd;
    background-color: #111419;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      padding: 32px 24px;
    }

    &-caption {
      color: #fafce9;
      /* Desktop/Label/Extra */
      font-family: "Roboto Slab", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */
      border-right: 1px solid #1e1e1e;
      padding-right: 103px;
      flex-shrink: 0;
      width: 45%;
      margin-right: 103px;

      @media screen and (max-width: 1220px) {
        font-size: 23px;
        font-weight: 400;
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
        margin-bottom: 32px;
        width: auto;
      }
    }

    &-description {
      color: #d2d2d2;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    &-button {
      margin: 0 auto;
      background-color: #8dc53e;
      color: #fff;
      width: fit-content;
      @media screen and(max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.first-block {
  margin: 40px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
  }

  &__image-container {
    width: 584px;
    height: auto;
    margin-left: 74px;
    flex-shrink: 0;

    @media screen and (max-width: 1220px) {
      margin-top: 32px;
      margin-left: 0;
      width: 100%;
    }
  }

  &_reverse {
    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
    .first-block {
      &__image-container {
        margin-right: 0;
        margin-left: 74px;

        @media screen and (max-width: 1220px) {
          margin-top: 32px;
          margin-left: 0;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  &__text-container {
    margin: auto 0;
  }

  &__caption {
    margin-bottom: 40px;
    color: #fafce9;
    /* Desktop/Label/Extra */
    font-family: "Roboto Slab", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */

    @media screen and (max-width: 1220px) {
      font-weight: 400;
      font-size: 23px;
    }
  }

  &__description {
    margin-bottom: 16px;
    color: #d2d2d2;
    font-family: "Roboto Slab", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  &__button {
    width: fit-content;
    background-color: #8dc53e;
    padding: 10px 18px;
    color: #fff;

    @media screen and (max-width: 1220px) {
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &:hover {
      background-color: #79aa35;
    }
  }
}
</style>
<style lang="scss">
.epitel-quiz {
  sup {
    vertical-align: super;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: inherit;
    white-space: nowrap;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
